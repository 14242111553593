import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '../../state/mainAppState';
import { MagicUseCase } from '../magicUseCase';
import { GetRentalApplicationStatusUseCase } from './application-steps/getRentalApplicationStatusUseCase';

export class GetRentalApplicationUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    const existingApplication = await rentalApplicationGateway.getRentalApplication(credentials);
    if (existingApplication) {
      this.getState().user.rentalApplication.application = existingApplication;
    } else {
      this.getState().user.rentalApplication.application = {
        credentials: credentials,
        newFiles: [],
      };
    }
    this.getState().user.rentalApplication.employmentHistoryErrors = [];
    this.getState().user.rentalApplication.rentalHistoryErrors = [];
    this.getState().user.rentalApplication.errors = [];
    await new GetRentalApplicationStatusUseCase().execute(credentials);
  }
}
